import React, { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Invoice, Order } from '@alliance-disposal/transport-types';
import { Button, Dialog, TextField } from '@wayste/sour-ui';
import { formatServiceAddress, getDateFormat, getInvoiceTerm, pricingBreakdownTotal, round, useYupValidationResolver } from '@wayste/utils';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { differenceInCalendarDays, format } from 'date-fns';
import { FormProvider, useFieldArray, useForm, useWatch } from 'react-hook-form';
import * as Yup from 'yup';
import { UIContext, useConfirmationDialog } from '../../../contexts';
import { ccRate } from '../../../utils/pricing-utils';
import { priceTypesEnums } from '../../../utils/shared-types';
import InternalOrderNotes from '../../InternalOrderNotes';
import OrderImageHandler from '../../OrderImageHandler';
import { CustomerInfoTable } from './CustomerInfoTable';
import { PayableGroup } from './PayableGroup';

const validationSchema = Yup.object().shape({
    priceType: Yup.string().oneOf(Object.keys(priceTypesEnums)),
    extensionDays: Yup.number(),
    rentExtensionFee: Yup.number(),
    formPayables: Yup.array().of(
        Yup.object().shape({
            invoiceDetails: Yup.object().shape({
                invoiceNumber: Yup.string().required('A Hauler Invoice Number is Required'),
                lineItems: Yup.array().of(
                    Yup.object().shape({
                        itemName: Yup.string().required('An item is required'),
                        totalPrice: Yup.number().typeError('A total is required').required('A total is required'),
                    }),
                ),
            }),
        }),
    ),
});

export const blankHaulerCost = {
    itemName: '',
    quantity: '' as const,
    unitPrice: '' as const,
    description: '',
    totalPrice: '' as const,
    taxable: false,
};

export type FormPayable = Omit<Partial<Invoice.PayableTransport>, 'invoiceDetails'> & {
    payableID?: string; // added because FormPayable has the id set as the form field id, not the payable ID
    invoiceDetails: Omit<Partial<Invoice.ReceivableTransport['invoiceDetails']>, 'lineItems'> & {
        invoiceNumber: string;
        lineItems: {
            itemName: string;
            quantity: number | '';
            unitPrice: number | '';
            description: string;
            totalPrice: number | '';
            taxable: boolean;
        }[];
    };
};

interface FormProps {
    priceType: 'ton' | 'flat' | 'yard';
    tonsDumped?: number | '';
    extensionDays?: number;
    rentExtensionFee?: number;
    invoiceNumber?: string;
    formPayables: FormPayable[];
}

interface Props {
    order: Order.AllianceOrderTransport;
    open: boolean;
    onCancel: () => void;
    onSubmitSuccessful: (newStatus: Order.OrderStatus) => void;
}

const OrderEnterTicket = ({ order, open, onCancel, onSubmitSuccessful }: Props) => {
    const [orderWithInvoice, setOrderWithInvoice] = useState<
        | (Order.AllianceOrderTransport & {
              payables: Invoice.PayableTransport[];
              receivables: Invoice.ReceivableTransport[];
          })
        | null
    >(null);
    const client = useWaysteClient();
    const { getConfirmation } = useConfirmationDialog();
    const { showFlash } = useContext(UIContext);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const getOrdersWithInvoice = async (order: Order.AllianceOrderTransport) => {
        const receivables = await client.invoice().adminPortal.receivable.query({
            orderID: order.id,
        });

        const payables = await client.invoice().adminPortal.payable.query({
            orderID: order.id,
        });

        setOrderWithInvoice({
            ...order,
            receivables: receivables.results || [],
            payables: payables.results || [],
        });
    };

    useEffect(() => {
        if (order) {
            getOrdersWithInvoice(order);
        }
    }, [order?.id]);

    const prepareFormData = (
        data: Order.AllianceOrderTransport & {
            payables: Invoice.PayableTransport[];
            receivables: Invoice.ReceivableTransport[];
        },
    ) => {
        const endDate = (order.rentalEndDate || order.expectedPickupDate)?.replace(/-/g, '/');

        // calculate extension days
        const daysOver = !endDate
            ? 0
            : differenceInCalendarDays(new Date(endDate), new Date(order.expectedDeliveryDate?.replace(/-/g, '/'))) -
              order.adjustedRentalPeriod.value;

        const newData: {
            priceType: 'ton' | 'flat' | 'yard';
            tonsDumped?: number | '';
            extensionDays?: number;
            rentExtensionFee?: number;
            invoiceNumber?: string;
            formPayables: FormPayable[];
        } = {
            priceType: data.priceType ? (data.priceType as 'ton' | 'flat' | 'yard') : 'ton',
            tonsDumped: data.actualWeightDumped?.value || '',
            rentExtensionFee: data.rentExtensionFee || 0,
            extensionDays: daysOver > 0 ? daysOver : 0,
            invoiceNumber: '',
            formPayables: [],
        };

        // Format payables to be used in a frontend form and put at least one payable if none exist
        if (data.payables && data.payables.length > 0) {
            newData.formPayables = data.payables.map((payable) => {
                const formPayable: FormPayable = {
                    ...payable,
                    payableID: payable.id,
                    invoiceDetails: {
                        ...payable.invoiceDetails,
                        invoiceNumber: payable.invoiceDetails.invoiceNumber || '',
                        lineItems: payable.invoiceDetails.lineItems.map((item) => ({
                            ...item,
                            quantity: item.quantity || '',
                            unitPrice: item.unitPrice || '',
                            totalPrice: item.totalPrice || '',
                        })),
                    },
                };
                return formPayable;
            });
        } else {
            const requiredBill = {
                invoiceDetails: {
                    invoiceNumber: '',
                    lineItems: [
                        {
                            ...blankHaulerCost,
                            itemName: 'haul',
                        },
                        {
                            ...blankHaulerCost,
                            itemName: 'dump',
                        },
                    ],
                },
            };
            newData.formPayables.push(requiredBill);
        }

        return newData;
    };

    // Upon fetching data set the form data
    useEffect(() => {
        if (!orderWithInvoice) return;
        const defaults = prepareFormData(orderWithInvoice);
        methods.reset(defaults);
    }, [orderWithInvoice]);

    const methods = useForm<FormProps>({
        mode: 'all',
        defaultValues: {},
        resolver: useYupValidationResolver(validationSchema),
    });

    const {
        control,
        register,
        handleSubmit,
        formState: { isValid, isDirty, errors },
        watch,
    } = methods;

    // Use useFieldArray to handle dynamic array fields
    const { fields: formPayableFields, append: appendBill } = useFieldArray({
        control,
        name: 'formPayables',
    });

    const watchFormPayables = useWatch({ name: 'formPayables', control });
    const watchTonsDumped = watch('tonsDumped');

    // TODO CHECK THIS WORKS PROPERLY - only used in submit
    // Check to see if rental ext fees already exist, then see if user wants to add more
    const rentalExtHandler = async (lastReceivableIndex: number, values: FormProps) => {
        if (!orderWithInvoice) return;
        if (JSON.stringify(orderWithInvoice.receivables).includes('Rental Extension')) {
            const confirmed = await getConfirmation({
                title: 'Rental Extension Already Exists',
                message: 'An invoice in this Order already includes a Rental Extension line item. Are you sure you want to add another?',
            });
            if (!confirmed) return null;
        }
        const invoiceCCItem = orderWithInvoice.receivables[lastReceivableIndex].invoiceDetails.lineItems.find(
            (item) => item.itemName === 'CC Fee',
        );
        const priceBreakdown = pricingBreakdownTotal(
            round((values.extensionDays ? +values.extensionDays : 0) * (values.rentExtensionFee ? +values.rentExtensionFee : 0)),
            values.extensionDays ? +values.extensionDays : 0,
            0,
            invoiceCCItem ? ccRate : 0,
        );
        const rentExtItem = {
            itemName: 'Rental Extension',
            quantity: priceBreakdown.quantity,
            unitPrice: priceBreakdown.unitPriceDollars, // unitPriceDollars is actually in cents
            description: `${values.extensionDays} days`,
            totalPrice: round(priceBreakdown.quantity * priceBreakdown.unitPriceDollars), // unitPriceDollars is actually in cents
            taxable: false,
        };
        return {
            totalPrice: +round(
                (values.extensionDays ? +values.extensionDays : 0) * (values.rentExtensionFee ? +values.rentExtensionFee : 0),
            ),
            item: rentExtItem,
            ccFee: priceBreakdown.ccFee,
        };
    };

    // TODO CHECK THIS WORKS PROPERLY - used in submit only
    const overageHandler = async (lastReceivableIndex: number, values: FormProps) => {
        if (!orderWithInvoice) return;
        if (JSON.stringify(orderWithInvoice.receivables).includes('Overage')) {
            const confirmed = await getConfirmation({
                title: 'Overage Already Exists',
                message: 'An invoice in this Order already includes an Overage line item. Are you sure you want to add another?',
            });
            if (!confirmed) return null;
        }

        const totalPrice = ((Number(values.tonsDumped) || 0) - (Number(order?.weightLimit?.value) || 0)) * (Number(order.overage) || 0);
        const invoiceCCItem = orderWithInvoice.receivables[lastReceivableIndex].invoiceDetails.lineItems.find(
            (item) => item.itemName === 'CC Fee',
        );
        const priceBreakdown = pricingBreakdownTotal(
            ((Number(values.tonsDumped) || 0) - (Number(order?.weightLimit?.value) || 0)) * (Number(order.overage) || 0),
            (Number(values.tonsDumped) || 0) - (Number(order.weightLimit?.value) || 0),
            0,
            invoiceCCItem ? ccRate : 0,
        );
        const overageItem = {
            itemName: 'Overage',
            quantity: priceBreakdown.quantity,
            unitPrice: priceBreakdown.unitPriceDollars, // unitPriceDollars is actually in cents
            description: `${priceBreakdown.quantity} tons over the ${order.weightLimit?.value} ton weight limit.`,
            totalPrice: round(priceBreakdown.quantity * priceBreakdown.unitPriceDollars), // unitPriceDollars is actually in cents
            taxable: false,
        };
        return {
            totalPrice: +totalPrice,
            item: overageItem,
            ccFee: priceBreakdown.ccFee,
        };
    };

    const onSubmit = async (values: FormProps) => {
        if (!orderWithInvoice) return;
        setIsSubmitting(true);
        let rentExtLineItem = null;
        let overageLineItem = null;

        const lastReceivableInvoiceNumber = Math.max(
            ...orderWithInvoice.receivables.map((item) => +(item?.invoiceDetails?.invoiceNumber || 0)),
        ).toString();
        const lastReceivableIndex = orderWithInvoice.receivables.findIndex(
            (receivable) => receivable.invoiceDetails.invoiceNumber === lastReceivableInvoiceNumber,
        );
        const weightDumped = values.tonsDumped ? Number(values.tonsDumped) : ''; // TODO is this an object
        let newOrderStatus = order.status;
        let newCCFee = 0;

        // Check to see if all needed info from hauler has been entered to complete the order
        if (
            values.formPayables.some((payableItem) => payableItem.invoiceDetails.lineItems.some((item) => item.itemName === 'haul')) &&
            values.formPayables.some((payableItem) => payableItem.invoiceDetails.lineItems.some((item) => item.itemName === 'dump')) &&
            (order.priceType === priceTypesEnums.ton ? weightDumped || weightDumped === 0 : true)
        ) {
            newOrderStatus = 'COMPLETED';
        }

        // Check to see if rental ext fees
        // Check to see if overage fee
        // If other than check if going on existing invoice or new invoice

        // Check to see if an invoice line item needs to be created for rental extension fee
        if (values.extensionDays && values.extensionDays > 0 && values.rentExtensionFee && values.rentExtensionFee > 0) {
            const rentExtFeeResponse = await rentalExtHandler(lastReceivableIndex, values);
            if (rentExtFeeResponse) {
                rentExtLineItem = rentExtFeeResponse.item;
                newCCFee = round(newCCFee + rentExtFeeResponse.ccFee);
            }
        }

        // Check to see if an invoice line item needs to be created for an overage fee
        if (
            Number(values?.tonsDumped || 0) > Number(order?.weightLimit?.value || 0) &&
            (Number(order.overage) > 0 || Number(order.overage) < 0)
        ) {
            const overageFeeResponse = await overageHandler(lastReceivableIndex, values);
            if (overageFeeResponse) {
                overageLineItem = overageFeeResponse.item;
                newCCFee = round(newCCFee + overageFeeResponse.ccFee);
            }
        }
        // If a rental extension or overage fee has to be added, add it to the last DRAFT receivable or create a new one
        if (rentExtLineItem || overageLineItem) {
            const newLineItems: Invoice.LineItemInputTransport[] = [];
            if (rentExtLineItem) newLineItems.push(rentExtLineItem);
            if (overageLineItem) newLineItems.push(overageLineItem);

            // if there is a DRAFT receivable update it otherwise we create a new invoice
            const lastReceivable = orderWithInvoice.receivables[lastReceivableIndex];
            if (lastReceivable.invoiceDetails.status === 'DRAFT') {
                const receivableUpdateObject: Invoice.ReceivableUpdateTransport = {
                    invoiceDetails: {
                        lineItems: [...(lastReceivable.invoiceDetails.lineItems || []), ...newLineItems],
                    },
                };
                // Check to see if the invoice already has a CC line item, if so it needs to be updated
                const ccFeeItemIndex = lastReceivable.invoiceDetails.lineItems?.findIndex((item) => item.itemName === 'CC Fee');
                if (ccFeeItemIndex >= 0) {
                    const lineItemsCopy = [...lastReceivable.invoiceDetails.lineItems];
                    const ccLineItemCopy = {
                        ...lineItemsCopy[ccFeeItemIndex],
                        unitPrice: round((lineItemsCopy[ccFeeItemIndex].unitPrice || 0) + newCCFee),
                        totalPrice: round((lineItemsCopy[ccFeeItemIndex].totalPrice || 0) + newCCFee),
                    };
                    delete ccLineItemCopy.totalPriceDollars;
                    delete ccLineItemCopy.unitPriceDollars;
                    receivableUpdateObject.invoiceDetails?.lineItems?.splice(ccFeeItemIndex, 1, ccLineItemCopy);
                }
                try {
                    await client.invoice().adminPortal.receivable.update(lastReceivable.id, receivableUpdateObject);
                } catch (error) {
                    console.error('error: ', error);
                    showFlash('An Error Occurred Updating Invoice', 'error');
                }
            } else {
                if (newCCFee) {
                    newLineItems.push({
                        itemName: 'CC Fee',
                        quantity: 1,
                        description: '',
                        unitPrice: newCCFee,
                        totalPrice: newCCFee,
                        taxable: false,
                    });
                }

                const newReceivable: Invoice.ReceivableCreateTransport = {
                    customerID: order.allianceCustomerID,
                    invoiceDetails: {
                        lineItems: newLineItems,
                        taxRate: lastReceivable.invoiceDetails.taxRate,
                        orderID: order.id,
                        orderNumber: order.orderNumber?.toString(),
                        invoiceNumber: (Number(lastReceivable.invoiceDetails.invoiceNumber) + 1).toString(),
                        taxAmount: newLineItems.reduce(
                            (total, item) =>
                                round(total + (item.taxable ? (item.totalPrice || 0) * lastReceivable.invoiceDetails.taxRate : 0)),
                            0,
                        ),
                        orderServiceLocation: order.serviceLocation,
                        term: getInvoiceTerm(order.paymentTerm || ''),
                    },
                };

                const updatedInvoices = [...orderWithInvoice.receivables, newReceivable];
                const uniqInvoiceSet = new Set(
                    [...(orderWithInvoice?.receivables || []), newReceivable].map((invoice) => JSON.stringify(invoice)),
                );
                if (uniqInvoiceSet.size < updatedInvoices.length) {
                    alert('Grab an AAP dev, duplicate invoices creating now...');
                }
                try {
                    await client.invoice().adminPortal.receivable.create(newReceivable);
                } catch (error) {
                    console.error('error: ', error);
                    showFlash('An Error Occurred Creating New Invoice', 'error');
                }
            }
        }

        // handleSubmitDumped(updateObj, updatedInvoices, updatedBills);

        const orderUpdateObject: Order.AllianceOrderUpdateInput = {
            haulerID: order.haulerID,
            status: newOrderStatus,
        };
        if (weightDumped || weightDumped === 0) {
            orderUpdateObject.actualWeightDumped = { value: weightDumped, unit: 'TONS' };
        }
        await client.order().adminPortal.update(order.id, orderUpdateObject);

        ////////////////////////////////////////////// payables
        const uniqBillSet = new Set(values.formPayables.map((payable) => JSON.stringify(payable)));
        if (uniqBillSet.size < values.formPayables.length) {
            alert('Grab an AAP dev, duplicate bills creating now...');
        }
        for (const payable of values.formPayables) {
            if (payable.payableID) {
                const payableUpdateObj: Invoice.PayableUpdateTransport = {
                    haulerID: payable.haulerID || (order.haulerID as string),
                    vendorName: payable.vendorName || order.vendorName,
                    invoiceDetails: {
                        taxAmount: payable.invoiceDetails.taxAmount,
                        taxRate: payable.invoiceDetails.taxRate || order.taxRate || undefined,
                        lineItems: payable.invoiceDetails.lineItems.map((item) => {
                            if (
                                (!item.quantity && !item.unitPrice) ||
                                (item.quantity !== '' && item.unitPrice !== '' && round(item.quantity * item.unitPrice) !== item.totalPrice)
                            ) {
                                return {
                                    ...item,
                                    quantity: 1,
                                    totalPrice: Number(item.totalPrice),
                                    unitPrice: Number(item.totalPrice),
                                };
                            }
                            return {
                                ...item,
                                quantity: Number(item.quantity),
                                unitPrice: Number(item.unitPrice),
                                totalPrice: Number(item.totalPrice),
                            };
                        }),
                        issueDate: payable.invoiceDetails.issueDate ? payable.invoiceDetails.issueDate : undefined,
                        invoiceNumber: payable.invoiceDetails.invoiceNumber,
                        orderID: payable.invoiceDetails.orderID || order.id,
                    },
                };

                try {
                    await client.invoice().adminPortal.payable.update(payable.payableID, payableUpdateObj);
                } catch (error) {
                    console.error('error: ', error);
                    showFlash('An Error Occurred', 'warning');
                }
            } else {
                const payableCreateObject: Invoice.PayableCreateTransport = {
                    haulerID: order.haulerID as string,
                    vendorName: order.vendorName,
                    invoiceDetails: {
                        dueDate: payable.invoiceDetails.dueDate ? new Date(payable.invoiceDetails.dueDate).toISOString() : undefined,
                        invoiceNumber: payable.invoiceDetails.invoiceNumber,
                        issueDate: payable.invoiceDetails.issueDate
                            ? new Date(payable.invoiceDetails.issueDate).toISOString()
                            : new Date().toISOString(),
                        lineItems: payable.invoiceDetails.lineItems.map((item) => {
                            if (
                                (!item.quantity && !item.unitPrice) ||
                                (item.quantity !== '' && item.unitPrice !== '' && round(item.quantity * item.unitPrice) !== item.totalPrice)
                            ) {
                                return {
                                    ...item,
                                    quantity: 1,
                                    totalPrice: Number(item.totalPrice),
                                    unitPrice: Number(item.totalPrice),
                                };
                            }
                            return {
                                ...item,
                                quantity: Number(item.quantity),
                                unitPrice: Number(item.unitPrice),
                                totalPrice: Number(item.totalPrice),
                            };
                        }),
                        orderID: order.id,
                        orderNumber: order?.orderNumber?.toString() || '',
                        orderServiceLocation: order.serviceLocation,
                        taxRate: payable.invoiceDetails.taxRate || order.taxRate || 0,
                        taxAmount: payable.invoiceDetails.taxAmount,
                        term: 30,
                    },
                };

                try {
                    await client.invoice().adminPortal.payable.create(payableCreateObject);
                } catch (error) {
                    console.error('error: ', error);
                    showFlash('An Error Occurred', 'warning');
                }
            }
        }

        onSubmitSuccessful(newOrderStatus as Order.OrderStatus);
    };

    const onAddAnotherPayableClicked = () => {
        appendBill({
            invoiceDetails: {
                invoiceNumber: '',
                lineItems: [],
            },
        });
    };

    if (!order || !orderWithInvoice || orderWithInvoice.receivables.length < 1) return <div>Error missing invoices or order</div>;

    return (
        <Dialog open={open} onClose={onCancel} styledTitle="Enter Hauler Invoice &amp; Dump Ticket" className="max-w-screen-2xl">
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div>Enter the expense information for order {order.orderNumber}:</div>
                    <div className="my-2">{formatServiceAddress(order.serviceLocation.address)}</div>
                    <div>
                        {`Delivered: ${format(new Date(getDateFormat(order.expectedDeliveryDate)), 'EEE MM/dd/yy')} | Picked Up: ${
                            order.expectedPickupDate ? format(new Date(getDateFormat(order.expectedPickupDate)), 'EEE MM/dd/yy') : ''
                        }`}
                    </div>
                    <OrderImageHandler order={order} inline showUploadDropZones />
                    <InternalOrderNotes order={order} collapsible />
                    <div className="grid gap-4">
                        <div className="w-full">
                            <h6 className="text-xl font-medium">Info from Hauler</h6>
                        </div>
                        {order.priceType === priceTypesEnums.ton ? (
                            <div className="w-full">
                                <div className="max-w-[250px]">
                                    <TextField
                                        label="Tons dumped"
                                        required
                                        inputProps={{
                                            ...register('tonsDumped', { required: true }),
                                            step: '0.01',
                                        }}
                                        type="number"
                                        error={errors?.tonsDumped}
                                    />
                                </div>
                            </div>
                        ) : null}
                        <hr />
                        {formPayableFields.map((formPayable, formPayableIndex) => (
                            <React.Fragment key={formPayable.id + formPayableIndex}>
                                <PayableGroup
                                    formPayable={formPayable}
                                    formPayableIndex={formPayableIndex}
                                    order={order}
                                    watchTonsDumped={watchTonsDumped}
                                    onFormPayableChange={(newFormPayable) => {
                                        methods.reset({
                                            ...methods.getValues(),
                                            formPayables: methods
                                                .getValues()
                                                .formPayables.map((item, index) => (index === formPayableIndex ? newFormPayable : item)),
                                        });
                                    }}
                                />
                                {formPayableIndex !== formPayableFields.length - 1 && <hr />}
                            </React.Fragment>
                        ))}
                        <div className="flex w-full flex-row items-center justify-center">
                            <hr className="w-full" />
                            <div className="flex flex-row items-center justify-center space-x-2 px-2">
                                <Button
                                    className="btn-secondary-text-only whitespace-nowrap"
                                    onClick={onAddAnotherPayableClicked}
                                    startIcon={<PlusCircleIcon />}
                                >
                                    Add another payable from hauler
                                </Button>
                            </div>
                            <hr className="w-full" />
                        </div>
                        <div className="w-full">
                            <div>
                                <p className="text-xs">(if invoice is from a Different Hauler you must add it via the Billing Screen)</p>
                            </div>
                        </div>
                        <CustomerInfoTable order={order} watchActualWeightDumped={watchTonsDumped} />
                    </div>
                    <div className="mt-4 flex justify-end gap-4 border-t pt-4">
                        <button onClick={onCancel} className="btn-dark-grey-outlined" type="button" disabled={isSubmitting}>
                            Cancel
                        </button>
                        <Button
                            loading={isSubmitting}
                            type="submit"
                            className="btn-primary"
                            disabled={
                                isSubmitting ||
                                !isValid ||
                                !isDirty ||
                                !watchFormPayables.some((billItem) =>
                                    billItem.invoiceDetails.lineItems.some((item) => item.itemName === 'haul'),
                                ) ||
                                !watchFormPayables.some((billItem) =>
                                    billItem.invoiceDetails.lineItems.some((item) => item.itemName === 'dump'),
                                )
                            }
                        >
                            Save
                        </Button>
                    </div>
                </form>
            </FormProvider>
        </Dialog>
    );
};

export default OrderEnterTicket;
