import React, { useEffect, useRef, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, InternalTicket, Order, Profile } from '@alliance-disposal/transport-types';
import { AutoComplete, AutoCompleteOption, Toggle } from '@wayste/sour-ui';
import {
    DndContext,
    DragEndEvent,
    DragOverEvent,
    DragOverlay,
    DragStartEvent,
    DropAnimation,
    KeyboardSensor,
    PointerSensor,
    closestCorners,
    defaultDropAnimation,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { BoardSection } from './BoardSections';
import { DetailsDrawer } from './DetailsDrawer';
import { TicketItem } from './TicketItem';

type BoardSectionsType = {
    [name: string]: InternalTicket.SAPInternalTicketTransport[];
};

const BOARD_SECTIONS = { undefined: 'No Status', ...InternalTicket.InternalTicketStatusLabels };

const getTicketByStatus = (
    tickets: InternalTicket.SAPInternalTicketTransport[],
    status: InternalTicket.InternalTicketStatus | 'undefined',
) => {
    return tickets.filter((ticket) => {
        if (status === 'undefined') {
            return ticket.status === undefined;
        }
        return ticket.status === status;
    });
};

const initializeBoard = (tickets: InternalTicket.SAPInternalTicketTransport[]) => {
    const boardSections: BoardSectionsType = {};

    Object.keys(BOARD_SECTIONS).forEach((boardSectionKey) => {
        boardSections[boardSectionKey] = getTicketByStatus(tickets, boardSectionKey as InternalTicket.InternalTicketStatus);
    });

    return boardSections;
};

const findBoardSectionContainer = (boardSections: BoardSectionsType, id: string) => {
    if (id in boardSections) {
        return id;
    }

    const container = Object.keys(boardSections).find((key) => boardSections[key].find((item) => item.id === id));
    return container;
};

const getTicketById = (tickets: InternalTicket.SAPInternalTicketTransport[], id: string) => {
    return tickets.find((ticket) => ticket.id === id);
};

export type Entities = { [key: string]: Order.AllianceOrderTransport | Customer.AllianceCustomerTransport | object };

const InternalTicketWorkflow = () => {
    const client = useWaysteClient();
    const currentUser = client.user().get();
    const [rosterMap, setRosterMap] = useState({});
    const [profileOptions, setProfileOptions] = useState<Profile.ProfileTransport[]>([]);
    const entities = useRef<Entities>({}); // need to useRef to reduce number of entity fetches
    const [allTickets, setAllTickets] = useState<InternalTicket.SAPInternalTicketTransport[]>([]);
    const [tickets, setTickets] = useState<InternalTicket.SAPInternalTicketTransport[]>([]);
    const [boardSections, setBoardSections] = useState<BoardSectionsType>(initializeBoard([]));
    const [activeTicketId, setActiveTicketId] = useState<null | string>(null);
    const [updateSignal, setUpdateSignal] = useState(0); // State to trigger re-renders on entities ref change
    const [drawerIsOpen, setDrawerIsOpen] = useState<{ open: boolean; ticket?: InternalTicket.SAPInternalTicketTransport }>({
        open: false,
        ticket: undefined,
    });
    const [allTags, setAllTags] = useState<InternalTicket.TagTransport[]>([]);
    const allVendorNames = useRef<{ [key: string]: string }>({}); // need to useRef to make it easier
    // FILTERS State Start
    const [selectedTeam, setSelectedTeam] = useState<Profile.SourgumTeam | ''>('');
    const [selectedUser, setSelectedUser] = useState(currentUser.id);
    const [selectedTagID, setSelectedTagID] = useState<string>('');
    const [selectedTagCategory, setSelectedTagCategory] = useState<InternalTicket.TagCategory | ''>('');
    const [selectedVendorName, setSelectedVendorName] = useState<string>('');
    const [showTicketsIAssigned, setShowTicketsIAssigned] = useState(false);
    // FILTERS State End

    /** Used to store entities locally to reduce fetches needed */
    const handleAddEntity = async (entityID: string, entityType: InternalTicket.SourgumInternalTicketRelatedEntity) => {
        if (!entities.current[entityID]) {
            entities.current[entityID] = {};
            switch (entityType) {
                case 'sourgum-order': {
                    const orderResponse = await client.order().adminPortal.fetch(entityID);
                    entities.current[entityID] = orderResponse;
                    if (orderResponse.vendorName) allVendorNames.current[orderResponse.vendorName] = '';
                    break;
                }
                case 'sourgum-customer': {
                    const customerResponse = await client.customer().adminPortal.fetch(entityID);
                    entities.current[entityID] = customerResponse;
                    break;
                }
                case 'sourgum-service-grouping': {
                    const serviceGroupingResponse = await client.universalService().serviceGrouping.fetch(entityID);
                    entities.current[entityID] = serviceGroupingResponse;
                    break;
                }
                case 'sourgum-vendor': {
                    const vendorResponse = await client.vendorService().adminPortal.fetch(entityID);
                    entities.current[entityID] = vendorResponse;
                    break;
                }
                default:
                    break;
            }
            setUpdateSignal((prev) => prev + 1); // Trigger re-render
        }
    };

    const handleFilterByVendorName = (vendorName: string, passedTickets: InternalTicket.SAPInternalTicketTransport[]) => {
        if (vendorName) {
            const entitiesWithVendorName = Object.values(entities.current as Entities)
                .filter((entity) => (entity as Order.AllianceOrderTransport)?.vendorName === vendorName)
                .map((entity) => (entity as Order.AllianceOrderTransport)?.id);
            return passedTickets.filter((ticket) => entitiesWithVendorName.includes(ticket.entityID));
        }
        return passedTickets;
    };

    useEffect(() => {
        const filteredTickets = handleFilterByVendorName(selectedVendorName, allTickets);
        setTickets(filteredTickets);
        setBoardSections(initializeBoard(filteredTickets));
    }, [selectedVendorName]);

    const handleGetTickets = async () => {
        const baseQuery = {
            assignedTeam: selectedTeam && !showTicketsIAssigned ? selectedTeam : undefined,
            assignedToProfileID: selectedUser && !showTicketsIAssigned ? selectedUser : undefined,
            tags: selectedTagID ? selectedTagID : undefined,
            includeDeleted: false,
            includeReplies: true,
            createdByProfileID: showTicketsIAssigned ? currentUser.id : undefined,
        } as InternalTicket.SAPInternalTicketQuery;
        const notSolvedResponse = await client.internalTicket().adminPortal.query({
            ...baseQuery,
            status: 'OPEN,IN_PROGRESS,NULL',
        });
        const solvedResponse = await client.internalTicket().adminPortal.query({
            ...baseQuery,
            status: 'SOLVED',
            limit: 10,
        });
        const finalResults = [...notSolvedResponse.results, ...solvedResponse.results];
        const filteredTickets = handleFilterByVendorName(selectedVendorName, finalResults);
        setTickets(filteredTickets);
        setAllTickets(finalResults);
        setBoardSections(initializeBoard(finalResults));
    };

    const handleGetTags = async () => {
        const response = await client.internalTicket().adminPortal.tag.query({ deletedAt: false });
        setAllTags(response.results);
    };

    useEffect(() => {
        const response = client.profile().adminPortal.getRosterFromMemory().idToProfileMap;
        setRosterMap(response);
        const onlyActive = Object.values(response).filter((item) => item.active);
        const sortedProfiles = onlyActive.sort((a, b) => {
            return (a.firstName || '') > (b.firstName || '') ? 1 : -1;
        });
        setProfileOptions(sortedProfiles);
        handleGetTags();
    }, []);

    useEffect(() => {
        if (!selectedUser && !selectedTeam && !showTicketsIAssigned) return;
        if (selectedTagCategory) {
            allTags.filter((tag) => tag.category === selectedTagCategory);
        }
        handleGetTickets();
    }, [selectedTeam, selectedUser, selectedTagID, selectedTagCategory, showTicketsIAssigned]);

    const handleTicketItemClick = (ticket: InternalTicket.SAPInternalTicketTransport) => {
        setDrawerIsOpen({ open: true, ticket });
    };

    ///////////////////////////////////////////////////////
    // DRAG AND DROP SECTION START
    ///////////////////////////////////////////////////////

    const sensors = useSensors(
        useSensor(PointerSensor, { activationConstraint: { distance: 8 } }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    const handleDragStart = ({ active }: DragStartEvent) => {
        setActiveTicketId(active.id as string);
    };

    const handleDragOver = ({ active, over }: DragOverEvent) => {
        // Find the containers
        const activeContainer = findBoardSectionContainer(boardSections, active.id as string);
        const overContainer = findBoardSectionContainer(boardSections, over?.id as string);

        if (!activeContainer || !overContainer || activeContainer === overContainer) {
            return;
        }

        setBoardSections((boardSection) => {
            const activeItems = boardSection[activeContainer];
            const overItems = boardSection[overContainer];

            // Find the indexes for the items
            const activeIndex = activeItems.findIndex((item) => item.id === active.id);
            const overIndex = overItems.findIndex((item) => item.id !== over?.id);

            return {
                ...boardSection,
                [activeContainer]: [...boardSection[activeContainer].filter((item) => item.id !== active.id)],
                [overContainer]: [
                    ...boardSection[overContainer].slice(0, overIndex),
                    boardSections[activeContainer][activeIndex],
                    ...boardSection[overContainer].slice(overIndex, boardSection[overContainer].length),
                ],
            };
        });
    };

    const handleUpdateStatus = async (ticketID: string, newStatus: InternalTicket.InternalTicketStatus | null) => {
        await client.internalTicket().adminPortal.update(ticketID, { status: newStatus });
        handleGetTickets();
    };

    const handleDragEnd = async ({ active, over }: DragEndEvent) => {
        const activeContainer = findBoardSectionContainer(boardSections, active.id as string);
        const overContainer = findBoardSectionContainer(boardSections, over?.id as string);

        if (!activeContainer || !overContainer || activeContainer !== overContainer) {
            return;
        }

        const activeIndex = boardSections[activeContainer].findIndex((task) => task.id === active.id);
        const overIndex = boardSections[overContainer].findIndex((task) => task.id === over?.id);

        if (activeIndex !== overIndex) {
            setBoardSections((boardSection) => ({
                ...boardSection,
                [overContainer]: arrayMove(boardSection[overContainer], activeIndex, overIndex),
            }));
            if (activeTicketId && over?.id) {
                handleUpdateStatus(
                    activeTicketId,
                    overContainer === 'undefined' ? null : (overContainer as keyof typeof InternalTicket.InternalTicketStatusLabels),
                );
            }
        }

        setActiveTicketId(null);
    };

    const dropAnimation: DropAnimation = {
        ...defaultDropAnimation,
    };

    const ticket = activeTicketId ? getTicketById(tickets, activeTicketId) : null;

    ///////////////////////////////////////////////////////
    // DRAG AND DROP SECTION START
    ///////////////////////////////////////////////////////

    return (
        <div className="container mx-auto flex flex-1 flex-col gap-6 px-3 pb-10 pt-4">
            <div className="flex max-w-fit gap-4">
                {/* select user */}
                <AutoComplete
                    label="User"
                    onSelect={(value) => {
                        setSelectedUser(value);
                    }}
                    value={selectedUser}
                    helperText="User or team required"
                    disabled={showTicketsIAssigned}
                >
                    {profileOptions.map((profile) => (
                        <AutoCompleteOption value={profile.id} key={profile.id}>
                            {(profile.firstName || '') + ' ' + (profile.lastName || '')}
                        </AutoCompleteOption>
                    ))}
                </AutoComplete>
                {/* select team */}
                <AutoComplete
                    label="Team"
                    onSelect={(value) => {
                        setSelectedTeam(value as Profile.SourgumTeam | '');
                    }}
                    value={selectedTeam}
                    disabled={showTicketsIAssigned}
                >
                    {Object.entries(Profile.SourgumTeamLabels)
                        .sort()
                        .map((item) => (
                            <AutoCompleteOption value={item[0]} key={item[0]}>
                                {item[1]}
                            </AutoCompleteOption>
                        ))}
                </AutoComplete>
                <div className="flex items-center gap-4">
                    <AutoComplete
                        label="Tag name"
                        onSelect={(value) => {
                            setSelectedTagCategory('');
                            setSelectedTagID(value);
                        }}
                        value={selectedTagID}
                        disabled={showTicketsIAssigned}
                    >
                        {allTags.map((tag) => (
                            <AutoCompleteOption value={tag.id} key={tag.name}>
                                {tag.name}
                            </AutoCompleteOption>
                        ))}
                    </AutoComplete>
                    <AutoComplete
                        label="Tag category"
                        onSelect={(value) => {
                            setSelectedTagID('');
                            setSelectedTagCategory(value as keyof typeof InternalTicket.TagCategoryLabels);
                        }}
                        value={selectedTagCategory}
                        disabled={showTicketsIAssigned}
                    >
                        {Object.entries(InternalTicket.TagCategoryLabels)
                            .sort()
                            .map((item) => (
                                <AutoCompleteOption value={item[0]} key={item[0]}>
                                    {item[1]}
                                </AutoCompleteOption>
                            ))}
                    </AutoComplete>
                    <AutoComplete
                        onSelect={(value) => setSelectedVendorName(value)}
                        label="Vendor name"
                        value={selectedVendorName}
                        disabled={showTicketsIAssigned}
                    >
                        {Object.keys(allVendorNames.current)
                            .sort()
                            .map((vendorName) => (
                                <AutoCompleteOption value={vendorName} key={vendorName}>
                                    {vendorName}
                                </AutoCompleteOption>
                            ))}
                    </AutoComplete>
                    <div className="flex items-center gap-x-2 text-nowrap">
                        <Toggle value={showTicketsIAssigned} onChange={(value) => setShowTicketsIAssigned(value)} />
                        <div className="text-sm">Show tickets I assigned</div>
                    </div>
                </div>
            </div>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCorners}
                onDragStart={handleDragStart}
                onDragOver={handleDragOver}
                onDragEnd={handleDragEnd}
            >
                <div className="flex flex-1 gap-8">
                    {Object.keys(boardSections).map((boardSectionKey) => (
                        <React.Fragment key={boardSectionKey}>
                            <BoardSection
                                id={boardSectionKey}
                                title={BOARD_SECTIONS[boardSectionKey as keyof typeof BOARD_SECTIONS]}
                                tickets={boardSections[boardSectionKey]}
                                entities={entities.current}
                                onAddEntity={handleAddEntity}
                                key={updateSignal}
                                onTicketItemClick={handleTicketItemClick}
                                rosterMap={rosterMap}
                                onRefreshTickets={handleGetTickets}
                            />
                        </React.Fragment>
                    ))}
                    <DragOverlay dropAnimation={dropAnimation}>
                        {ticket ? (
                            <TicketItem
                                ticket={ticket}
                                entities={entities.current}
                                key={updateSignal}
                                rosterMap={rosterMap}
                                onRefreshTickets={handleGetTickets}
                            />
                        ) : null}
                    </DragOverlay>
                </div>
            </DndContext>
            <DetailsDrawer
                isOpen={drawerIsOpen.open}
                onClose={() => {
                    handleGetTickets();
                    setDrawerIsOpen({ open: false, ticket: undefined });
                }}
                ticket={drawerIsOpen.ticket}
            />
        </div>
    );
};

export default InternalTicketWorkflow;
